import React, { lazy, useContext } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import { UserContext } from './context/UserContext'

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))

function App() {
  const { isAuth } = useContext(UserContext)
  console.log(isAuth)
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
            {isAuth 
            ?
              <Switch>
                <Route path="/rtm" component={Layout} />
                <Redirect to='/rtm/validadores' />
              </Switch>
              :
                <Switch>
                  <Route path="/login" component={Login} />
                  <Redirect to='/login' />
                </Switch>
            }
      </Router>
    </>
  )
}

export default App
