import React, { createContext, useState, useEffect } from "react";

export const RenderContext = createContext();

export const RenderProvider = ({ children }) => {
	const [ reload, setReload ] = useState(false)

	useEffect(() => {
        console.log('pagina recargada')
	}, [reload])

  return (
    <RenderContext.Provider
			value={{
                reload,
                setReload
			}}	
		>
      {children}
    </RenderContext.Provider>
  );
};