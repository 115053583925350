import React, { createContext, useState, useEffect } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [ token, setToken ] = useState(localStorage.getItem("token") || null)
	const [ isAuth, setIsAuth ] = useState()

	useEffect(() => {
		if(token) {
			setIsAuth(true)
		}
		console.log('token cambiado')
		console.log(token)
		console.log(isAuth)
	}, [token])

  return (
    <UserContext.Provider
			value={{
				token,
				isAuth,
				setIsAuth,
				setToken
			}}	
		>
      {children}
    </UserContext.Provider>
  );
};